import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyD9hVY6QtdXNuzcNqJEMzxgtxOKcMQB2Aw",
  authDomain: "qrorder-aa0e8.firebaseapp.com",
  databaseURL:
    "https://qrorder-aa0e8-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "qrorder-aa0e8",
  storageBucket: "qrorder-aa0e8.appspot.com",
  messagingSenderId: "303581654898",
  appId: "1:303581654898:web:0907b9ab7e9e6f457a77eb",
};
firebase.initializeApp(firebaseConfig);

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ordered: [] ,
    firebase:firebase
  },
  mutations: {
    addOrder: ({ordered},dish)=>{
      let index=ordered.map((elementDish)=>elementDish.name).indexOf(dish.name);
      if(index!=-1){
        if(dish.quantity!=0){
          ordered[index]=dish;
        }else{
          let temp=[]     //FIXME ci sarà un modo migliore
          let length=ordered.length
          for (let i = 0; i < length; i++) {
            if(index!=i){
              temp.push(ordered.shift())
            }else{
              ordered.shift()
            }
          }
          temp.forEach((e)=>ordered.push(e))
          
        }
      }else{
        ordered.push(dish)
      }
    }
  },
  actions: {
    addOrder: ({commit},dish)=>{
      commit("addOrder",dish)
    }
  },
  modules: {
  }
})
