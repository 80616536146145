<template>
  <div class="dish">
    <img :src="image" align="left" v-if="image" />
    <p class="name">{{ name }}</p>
    <p class="price">{{ price | monetize }}</p>
    <p v-show="quantity" class="total-price">
      {{ (quantity * price) | monetize }}
    </p>
    <div class="counter">
      <button class="sub" :disabled="quantity == 0" @click="buttonSub()">
        -
      </button>
      <p class="quantity">{{ quantity }}</p>
      <button class="add" @click="buttonAdd()">+</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Dish",
  data() {
    return {
      quantity: this.baseQuantity,
      image:"",
      error:false,
    };
  },
  props: {
    name: String,
    price: Number,
    baseQuantity: Number,
  },
  filters: {
    monetize: function (value) {
      let [a, b] = (value + "").split(/,|\./);
      if (!b) {
        return a + " €";
      } else {
        return a + "," + (b + "0").substr(0, 2) + " €";
      }
    },
  },
  methods: {
    ...mapActions(["addOrder"]),
    buttonAdd() {
      this.quantity++;
      this.addOrder({name:this.name,quantity:this.quantity,price:this.price});
    },
     buttonSub() {
      this.quantity--;
      this.addOrder({name:this.name,quantity:this.quantity,price:this.price});
    }
  },mounted(){
    try {
      this.image=require(`@/assets/Dishes images/${this.name}.jpg`);
    } catch (error) {
      null
    }
  }
};
</script>


<style scoped>
* {
  margin: 0;
}
.dish {
  background-color: var(--primary);
  position: relative;
  padding: 2vw;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.61);
  margin: 2vw;
}
.name {
  display: block;
  min-height: 8vh;
}
.price,
.total-price {
  position: absolute;
  bottom: 1vh;
  right: 2vw;
}
.total-price {
  bottom: 3vh;
}
.dish * {
  text-align: left;
}
img {
  margin: 0 2vw 1vh 0;
  float: left;
  border-radius: 10px;
  object-fit: cover;
  width: 35vw;
  height: 8vh;
}
.counter {
  height: 8vw;
  margin-top: 1vh;
  display: block;
}
.quantity {
  display: inline-block;
  width: 19vw;
  text-align: center;
}
.newQuantity {
  text-align: left;
  margin-top: 1.5vh;
}
.add,
.sub {
  bottom: 1vh;
  width: 8vw;
  height: 8vw;
  display: inline;
  text-align: center;
}
.add {
  background-color: var(--secondary);
}
.sub {
  background-color: var(--tertiary);
}
</style>
