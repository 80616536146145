<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
    return {
    };
  },
  components: {

  },
};
</script>

<style>
@media (prefers-color-scheme: dark) {
	:root {
		--background: #26292f;
		--primary: #303538;
		--secondary: #03a5f6;
		--tertiary: #fe3d59;
		--text: #ffffff;
	}
}

@media (prefers-color-scheme: light) {
	:root {
		--background: #f1f1f1;
		--primary: #ffffff;
		--secondary: #03a5f6;
		--tertiary: #fe3d59;
		--text: #000000;
	}
}
* {
  margin: 0;
  padding: 0;
  color: var(--text);
  border: none;
  font-family: "Nunito", sans-serif;
  text-decoration: none;
}
body {
  background-color: var(--background);
}
input,
button,a {
  background-color: var(--primary);
  border-radius: 10px;
}
*:focus {
  outline: none;
}
button:hover {
  filter: brightness(85%);
  transform: scale(110%);
}
body{
	overflow: hidden;
}
</style>
