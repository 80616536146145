<template>
  <div class="menu">
    <div id="tool-bar">
      <input type="text" list="dishes_list" placeholder="Search" v-model="searchText" @keyup="search()" id="search" />
      <datalist id="dishes_list">
      </datalist>
      <router-link :to="this.$route.params.table+'/cart'" id="cart">Cart</router-link>
    </div>
    <Category
      v-for="category in categories"
      :name="category.name"
      :dishes="category.dishes"
      :key="category.name"
    />
  </div>
</template>

<script>
import Category from "../components/Category.vue";
import { mapState } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      categories: [],
      searchText: "",
    };
  },
  methods: {
    search(e) {
      console.log(e);
      document
        .querySelectorAll(".dish")
        .forEach(
          (dish) =>
            (dish.hidden = !dish
              .querySelector(".name")
              .innerText.toUpperCase()
              .includes(this.searchText.toUpperCase().trim()))
        );
    },
  },
  components: {
    Category,
  },
  computed: {
    ...mapState(["ordered", "firebase"]),
  },
  mounted() {
    this.firebase
      .database()
      .ref("restaurants/nome ristorante/dishes")
      .on("child_added", (e) => {
        let name = e.key;
        let dishes = Object.entries(e.val()).map((dishElement) => ({
          name: dishElement[0],
          price: dishElement[1].price,
        }));
        this.categories.push({ name: name, dishes: dishes });
        Object.entries(e.val()).map((dishElement)=>{
          let option=document.createElement("option")
          option.value=dishElement[0]
        document.getElementById("dishes_list").appendChild(option)
        });
      });
    document.querySelectorAll(".dish").forEach((e) => e.addEventListener(""));
  },
};
</script>
<style scoped>
#tool-bar {
  height: 6vh;
  background-color: var(--primary);
}
#cart,
#search {
  background-color: var(--secondary);
  padding: 1vh;
  vertical-align: middle;
  margin: 1vh 0 0 1vw;
  display: inline-block;
  height: 2vh;
}
#cart {
  float: right;
  margin-right: 2vw;
}
#search {
  background-color: var(--background);
}
</style>