<template>
  <div class="cart">
    <div id="tool-bar">
      <router-link :to="'/'+this.$route.params.table">Go back</router-link>
      <p>Order:</p>
      <p class="order-status" :class="{ 'no-order': orderStatus === 'none' }">
        {{ orderStatus }}
      </p>
      <span v-show="orderStatus !== 'none'" class="tooltip"
        >?
        <span class="tooltiptext">If you haven't ordered anything yet, contact the staff.</span>
      </span>
      <button @click="buy" class="buy">
        Order for {{ totalCost | monetize }}
      </button>
    </div>
    <Dish
      v-for="dish in ordered"
      :name="dish.name"
      :price="dish.price"
      :baseQuantity="dish.quantity"
      :key="dish.name"
    />
  </div>
</template>

<script>
import Dish from "../components/Dish.vue";
import { mapState } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      totalCost: 0,
      orderStatus: "none",
    };
  },
  components: {
    Dish,
  },
  filters: {
    monetize: function (value) {
      let [a, b] = (value + "").split(/,|\./);
      if (!b) {
        return a + " €";
      } else {
        return a + "," + (b + "0").substr(0, 2) + " €";
      }
    },
  },
  methods: {
    buy() {
      let sendOrder = true; //FIXME migliorabile?
      this.firebase
        .database()
        .ref("restaurants/nome ristorante/orders/"+this.$route.params.table+"/orders")
        .on("value", (e) => {
          if (sendOrder) {
            sendOrder = false;

            let ordered={}
            this.ordered.forEach(e=>{ordered[e.name]={quantity:e.quantity}});

            if (!e.val()) {
              this.firebase
              .database()
              .ref(
                "restaurants/nome ristorante/orders/"+this.$route.params.table
              )
              .set({orders:ordered,waiting:Math.floor(new Date() / 60000)});

            } else {
              alert(
                "ERROR!\n\nAn order has already been placed for this table!\nIf you haven't ordered anything yet, contact the staff."
              );
            }
          }
        });
    },
    calcCost() {
      this.totalCost = this.ordered
        .map((order) => order.price * order.quantity)
        .reduce((a, b) => a + b, 0);
    },
  },
  computed: {
    ...mapState(["ordered", "firebase"]),
  },
  mounted() {
    this.firebase
      .database()
      .ref("restaurants/nome ristorante/orders/"+this.$route.params.table+"/orders")
      .on("value", (e) => {
        if (e.val()) {
          this.orderStatus = "on delivery";
        } else {
          this.orderStatus = "none";
        }
      });
      this.calcCost();
      document.querySelectorAll(".add,.sub").forEach(button=>button.addEventListener("click",()=>this.calcCost()))
  },
};
</script>
<style scoped>
#tool-bar {
  height: 6vh;
  background-color: var(--primary);
}
a {
  background-color: var(--tertiary);
  padding: 1vh;
  vertical-align: middle;
  margin: 1vh 0 0 1vw;
  display: inline-block;
  height: 2vh;
}
.buy {
  background-color: var(--secondary);
  padding: 1vh;
  margin: 1vh 1vw 0 0;
  float: right;
  height: 4vh;
}
p,span {
  display: inline-block;
  height: 2vh;
  padding: 1vh 0;
  vertical-align: middle;
  margin: 1vh 0 0 2.5vw;
  font-size: 4vw;
}
.order-status {
  color: #00b318;
}
.no-order {
  color: var(--tertiary);
}
/*#region tooltip*/
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  padding-bottom: 0.3vh;
  padding-top: 0.3vh;
}

.tooltip .tooltiptext {
  visibility: hidden;
  height: 65px;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/*#endregion tooltip*/
</style>