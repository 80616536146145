<template>
  <div class="category">
    <div @click="e => e.target.parentNode.parentNode.classList.toggle('open')" class="title">
      <img v-if="image" :src="image"/>
      <p class="name">{{ name }}</p>
    </div>
    <div class="dishes">
      <Dish
        v-for="dish in dishes"
        :name="dish.name"
        :price="dish.price"
        :baseQuantity="ordered.map((elementDish)=>elementDish.name).includes(dish.name)?ordered[ordered.map((elementDish)=>elementDish.name).indexOf(dish.name)].quantity:0"

        :key="dish.name"
      />
    </div>
  </div>
</template>

<script>
import Dish from "./Dish.vue";

import {mapState} from "vuex"
export default {
  name: "Category",
  components: {
    Dish,
  },data(){
    return{
      image:"",
    }
  },
  props: {
    name: String,
    dishes: Array,
  },
  computed: {
    ...mapState([
      "ordered"
    ])
  },mounted(){
    try {
      this.image=require(`@/assets/Dishes images/${this.name}.jpg`);
    } catch (error) {
      null
    }
  }
};
</script>

<style scoped>
.category {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.61);
  margin: 2vw;
  padding-bottom: 0.5vw;
  max-height: 8vh;
  animation: close-category 0.3s;
  overflow: hidden;
}
.title{
  min-height: 8vh;
}
.name{
  padding: 5vw 4vw 2vw 2vw;
  font-size: 3vh;
  vertical-align:middle;
}
img{
  margin: 2vw;
  float: left;
  border-radius: 10px;
  object-fit: cover;
  width: 25vw;
  height: 6vh;
}
/*#region Dropdown*/
.open{
  max-height: 100vh;
  animation: open-category 0.6s;
}
@keyframes open-category {
	from {
		max-height: 8vh;
	}
	to {
		max-height: 100vh;
	}
}
@keyframes close-category {
	from {
		max-height: 100vh;
	}
	to {
		max-height: 8vh;
	}
}
/*#region Dropdown Arrow*/
.name::after {
	content: url("../assets/downArrow.png");
	transition: all 0.3s;
	float: right;
}
.category.open .name::after {
	transform: rotate(-180deg);
}
.category:not(.open) .name::after {
	transform: rotate(0deg);
}
/*#endregion Dropdown Arrow*/
/*#endregion Dropdown*/
</style>
